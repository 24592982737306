<template>
  <div class="logistics-page">
    <div class="logistics-bg"></div>
    <div class="logistics-main">
      <div class="logistics-header">
        <div class="icon">
          <img src="~@/assets/images/icon/icon_logistics.svg" alt="">
        </div>
        <div class="title">快递单号：{{ id }}</div>
      </div>
      <div class="logistics-content">
        <div class="log-detail-list">
          <div class="log-item" v-for="(item,index) in logList" :key="index">
            <div class="content">
              <div class="desc" v-html="item.action"></div>
              <div class="info">{{ item.actionTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
  import { getLogisticsInfo } from '@/api/order'
  import { Toast } from 'vant'

  export default {
    data() {
      return {
        id: '',
        logList: []
      }
    },
    created() {
      this.id = this.$route.params.id
      this.loadData()
    },
    methods: {
      loadData() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        })
        getLogisticsInfo({id: this.id}).then(res => {
          this.logList = res.data.list
          toast.clear()
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  .logistics-page {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    min-height: 100%;
    background: #F4F4F4;
    padding-bottom: calc(4vw + env(safe-area-inset-bottom));
    padding-bottom: calc(4vw + constant(safe-area-inset-bottom))
  }
  .logistics-bg {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: url(~@/assets/images/log_bg.jpg) no-repeat top center / cover;
    z-index: 0;
  }
  .logistics-header {
    width: 92vw;
    height: 12vw;
    border-radius: 1.6vw;
    margin: 5.3333vw auto 0;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    padding-left: 4vw;
    .icon {
      width: 4vw;
      height: 4vw;
      position: relative;
      img {
        width: 5.8667vw;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -10%)
      }
    }
    .title {
      margin-left: 2.6667vw;
      font-weight: bold;
      font-size: 3.7333vw;
      color: #1C1F20;
    }
  }
  .logistics-main {
    position: relative;
    z-index: 2
  }
  .logistics-content {
    width: 92vw;
    border-radius: 1.6vw;
    margin: 4vw auto 0;
    background: #FFFFFF;
    padding: 5.3333vw 4vw;
  }
  .log-detail-list {
    .log-item:last-child {
      padding-bottom: 0;
      &::before {
        display: none
      }
    }
  }
  .log-item {
    padding-left: 5.4667vw;
    position: relative;
    padding-bottom: 9.3333vw;
    &::before {
      content: '';
      display: block;
      width: 0.2667vw;
      height: 100%;
      position: absolute;
      top: 1.6vw;
      left: 1.2667vw;
      background: #F3F3F3;
    }
    &::after {
      content: '';
      display: block;
      width: 2vw;
      height: 2vw;
      border-radius: 50%;
      background: #F3F3F3;
      position: absolute;
      left: 0.4vw;
      top: 0.8vw;
      z-index: 5;
      
    }
    &:first-child {
      &::after {
        width: 2.8vw;
        height: 2.8vw;
        background: #186AFF;
        box-shadow: 0 0 1.4667vw 0 rgba(24,106,255,0.66);
        left: 0;
      }
      .desc {
        color: #1C1F20;
      }
    }
    .desc {
      font-size: 3.2vw;
      color: #9C9DAA;
      letter-spacing: 0;
      line-height: 1.5;
    }
    .info {
      font-size: 2.9333vw;
      color: #9C9DAA;
      letter-spacing: 0;
      line-height: 1.1;
      margin-top: 2.6667vw;
    }
  }
</style>