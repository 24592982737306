import request from '@/utils/request'

// 获取订单列表
export function getOrderList(data) {
  return request({
    url: '/member/get_order_list',
    method: 'post',
    data
  })
}

// 获取订单详情
export function getOrderDetail(data) {
  return request({
    url: '/member/get_order_info',
    method: 'post',
    data
  })
}

// 获取物流列表
export function getLogisticsList(data) {
  return request({
    url: '/member/get_logistics_list',
    method: 'post',
    data
  })
}

// 获取物流详情
export function getLogisticsInfo(data) {
  return request({
    url: '/member/get_logistics_info',
    method: 'post',
    data
  })
}

// 取消服务
export function cancelOrder(data) {
  return request({
    url: '/member/cancel_order',
    method: 'post',
    data
  })
}

// 删除订单
export function deleteOrder(data) {
  return request({
    url: '/member/del_order',
    method: 'post',
    data
  })
}

// 添加评价
export function rateOrder(data) {
  return request({
    url: '/member/add_comment',
    method: 'post',
    data
  })
}

// 催单
export function remindOrder(data) {
  return request({
    url: '/member/remind_order',
    method: 'post',
    data
  })
}

// 改约
export function updateOrderAppointTime(data) {
  return request({
    url: '/member/update_order_appoint_time',
    method: 'post',
    data
  })
}
